import React, { useState, useEffect } from 'react';
import mainLogo from '../Assests/EVENT_BEE-LOGO-light.png';
import { RiMenu5Line } from 'react-icons/ri';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleNavItemClick = () => {
    setNav(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 w-full z-40 transition-colors duration-300 ${
        isScrolled ? 'bg-amber-400' : 'bg-black'
      }`}
    >
      <div
        className={`flex justify-between items-center h-20 max-w-[1240px] mx-auto px-4 ${
          isScrolled ? 'text-black' : 'text-white'
        }`}
      >
        <div className="flex cursor-pointer">
          <a href="#home">
            <img src={mainLogo} alt="EventBee Logo" className="h-18 w-40" />
          </a>
        </div>
        <ul className="hidden text-xl md:flex font-bold  cursor-pointer">
          <li className="p-4  tracking-wide hover:text-amber-600">
            <a href="#home" onClick={handleNavItemClick}>Home</a>
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#services" onClick={handleNavItemClick}>Services</a>
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#events" onClick={handleNavItemClick}>Our Events</a>
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#about" onClick={handleNavItemClick}>About</a>
          </li>
          <li className="p-4 tracking-wide hover:text-amber-600">
            <a href="#contact" onClick={handleNavItemClick}>Contact</a>
          </li>
        </ul>
        <div
          onClick={handleNav}
          className={`block md:hidden cursor-pointer mr-2 ${
            isScrolled ? 'text-black' : 'text-amber-400'
          }`}
        >
          <RiMenu5Line size={24} />
        </div>
        <ul
          className={
            nav
              ? 'fixed left-0 top-0 w-[60%] h-full text-[#eec33d] border-r border-r-gray-900 bg-black ease-in-out duration-500'
              : 'ease-in-out duration-500 fixed left-[-100%]'
          }
        >
          <img src={mainLogo} alt="EventBee Logo" className="p-4 h-20" />
          <li onClick={handleNavItemClick} className="p-5 border-b border-gray-600">
            <a href="#home">Home</a>
          </li>
          <li onClick={handleNavItemClick} className="p-5 border-b border-gray-600">
            <a href="#services">Services</a>
          </li>
          <li onClick={handleNavItemClick} className="p-5 border-b border-gray-600">
            <a href="#events">Our Events</a>
          </li>
          <li onClick={handleNavItemClick} className="p-5 border-b border-gray-600">
            <a href="#about">About</a>
          </li>
          <li onClick={handleNavItemClick} className="p-5">
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
