import React from "react";
import wedImg from "../Assests/event-img/wedding.JPG"
import bdayImg from "../Assests/event-img/birthday.jpeg"
import babyShowerImg from "../Assests/event-img/baby-shower.webp"
import houseWarmImg from "../Assests/event-img/house-warming.jpeg"
import musicEventImg from "../Assests/event-img/music-event.jpg"
import networkImg from "../Assests/event-img/networking.jpeg"
import charityImg from "../Assests/event-img/charity.jpeg"
import culturalImg from "../Assests/event-img/cultural.JPG"
import corporateImg from "../Assests/event-img/corporate.jpg"
import educationalImg from "../Assests/event-img/educational.jpg"
import collegeImg from "../Assests/event-img/college.jpeg"
import sportsImg from "../Assests/event-img/sports.jpeg"

const images = [
  { src: wedImg, title: "Weddings" },
  { src: bdayImg, title: "Birthdays" },
  { src: babyShowerImg, title: "Baby Shower" },
  { src: houseWarmImg, title: "House Warming" },
  { src: musicEventImg, title: "Music Events" },
  { src: networkImg, title: "Networking Events" },
  { src: charityImg, title: "Charity Events" },
  { src: culturalImg, title: "Cultural Events" },
  { src: corporateImg, title: "Corporate Events" },
  { src: educationalImg, title: "Educational Workshop" },
  { src: collegeImg, title: "College Events" },
  { src: sportsImg, title: "Sports Events" },
];

const OurEvents = () => {
  return (
    <div className="">
      <div className="max-w-[1240px] mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-bold tracking-tight text-amber-400">Our Events</h2>
        <p className="mt-4 text-md md:text-lg text-gray-300">Explore a variety of events that we organize, from birthdays and weddings to corporate and cultural events. Each event is crafted with attention to detail to ensure an unforgettable experience.</p>
      </div>
      {/*Large Screen Layout*/}
      <div className="hidden lg:block">
      <div className="honeycomb">
        <li className="honeycomb-cell">
            <img
               className="honeycomb-cell_img"
               src={bdayImg}
               alt="Birthdays"
             />
            <div class="honeycomb-cell_title">Birthdays</div>
          </li>
          <li class="honeycomb-cell">
          <img
               className="honeycomb-cell_img"
               src={wedImg}
               alt="Weddings"
                 />
            <div class="honeycomb-cell_title">Weddings</div>
          </li>
          <li class="honeycomb-cell">
          <img
              className="honeycomb-cell_img"
               src={babyShowerImg}
               alt="Baby Shower"
             />
            <div class="honeycomb-cell_title">Baby Shower</div>
          </li>
          <li class="honeycomb-cell">
          <img
               className="honeycomb-cell_img"
               src={houseWarmImg}
               alt="House Warming"
            />
            <div class="honeycomb-cell_title">House Warming</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={corporateImg}
              alt="corporate-events"
            />
            <div class="honeycomb-cell_title">Corporate Events
            </div>
          </li>
        </div>

        <div className="honeycomb">
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={musicEventImg}
              alt="music-events"
            />
        <div class="honeycomb-cell_title">Music Events</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={sportsImg}
              alt="sports-events"
            />
            <div class="honeycomb-cell_title">Sports Events</div>
          </li>

          <li class="honeycomb-cell honeycomb_Hidden"></li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={culturalImg}
              alt="cultural-events"
            />
            <div class="honeycomb-cell_title">Cultural Events</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={charityImg}
              alt="charity-events"
            />
            <div class="honeycomb-cell_title">Charity Events</div>
          </li>
        </div>

        <div className="honeycomb">
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={educationalImg}
              alt="educational-workshops"
            />
            <div class="honeycomb-cell_title">Educational Workshop</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={collegeImg}
              alt="college-events"
            />
            <div class="honeycomb-cell_title">College Events</div>
          </li>
          <li class="honeycomb-cell">
            <img
              class="honeycomb-cell_img"
              src={networkImg}
              alt="networking-events"
            />
            <div class="honeycomb-cell_title">Networking Events</div>
          </li>
        </div>
      </div>


      {/* Small Screen Layout */}
      <div className="block lg:hidden">
      <div className="grid grid-cols-2 gap-3 p-4 justify-center items-center mx-auto py-8 transform translate-y-8">
        {images.map((image, index) => (
          <div key={index} className="relative flex justify-center cursor-pointer">
            <img src={image.src} alt={image.title} className="w-full h-[220px] rounded-md " />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 hover:opacity-100">
              <span className="text-amber-500 uppercase mt-4 text-center text-xl font-semibold">{image.title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>


    </div>

  );
};
export default OurEvents;

