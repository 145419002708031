import React from "react";
const WhatsAppBtn = ({phonenum, message}) =>{
    const url = "https://api.whatsapp.com/send/?phone=8682960528&text&type=phone_number&app_absent=0"
    return(
        <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-14 right-4 flex items-center justify-center p-4 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700"
        style={{ zIndex: 1000 }}
      >
        <svg
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 0C5.372 0 0 5.373 0 12c0 2.118.552 4.182 1.604 6.004L0 24l5.729-1.497C7.816 23.449 9.882 24 12 24c6.628 0 12-5.373 12-12S18.628 0 12 0zm6.332 17.71c-.253.683-1.485 1.31-2.04 1.394-.518.08-1.18.116-1.887-.12a17.457 17.457 0 01-1.91-.803c-3.355-1.657-5.519-4.96-5.687-5.197-.168-.237-1.353-1.804-1.353-3.436 0-1.632.85-2.443 1.15-2.789.299-.346.648-.434.864-.434s.432.01.619.012c.197.002.463-.076.728.555.291.673.992 2.332 1.079 2.5.087.168.145.361.027.579-.117.217-.18.352-.355.544-.174.193-.368.43-.528.576-.173.158-.353.328-.151.645.202.317.896 1.479 1.93 2.4.754.673 1.409.883 1.73 1.015.364.153.578.132.793-.08.215-.213.919-1.07 1.165-1.438.247-.366.493-.305.823-.183.33.123 2.084.985 2.44 1.165.356.181.594.273.681.427.087.155.087.714-.166 1.397z" />
        </svg>
      </a>
    )
}

export default WhatsAppBtn