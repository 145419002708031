import React from "react";
import { FiPhoneCall } from "react-icons/fi";

const Contact = () => {
  return (
    <div className="w-full min-h-screen contact-bg backdrop-blur-sm flex flex-col justify-center items-center">
      <h1 className="text-5xl font-bold text-center text-black mb-18 bg-white bg-opacity-50 rounded-xl p-3">
        Contact Us
      </h1>
      <div className="flex md:hidden">
        <div className="mt-4 flex gap-4 p-3 font-lg font-bold text-black bg-white bg-opacity-70 rounded-xl md:p-3 space-x-1 md:space-x-3">
          <div>
            <p>For Bookings</p>
            <FiPhoneCall
              className="flex w-4 h-4 mt-1 ml-9 font-bold"
              aria-hidden="true"
            />
          </div>
          <div>
            <ul>
              <li>+91-9025128482</li>
              <li>+91-8682960528</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="hidden md:flex">
        <div className="mt-4 flex font-lg font-bold text-black bg-white bg-opacity-70 rounded-xl md:p-3 space-x-1 md:space-x-3">
          <p>For Bookings</p>
          <FiPhoneCall className="flex w-4 h-4 mt-1 font-bold" aria-hidden="true" />
          <span>+91-9025128482</span>
          <span>||</span>
          <span>+91-8682960528</span>
        </div>
      </div>
      <div className="bg-transparent text-gray-900 px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex flex-col mt-6 w-full max-w-[600px] mx-auto space-y-5">
          <input
            className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            type="text"
            placeholder="Name"
          />
          <input
            className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            type="number"
            placeholder="Contact number"
          />
          <input
            className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            type="email"
            placeholder="Your email-id"
          />
          <input
            className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
            type="text"
            placeholder="Message"
          />
          <button className="tracking-wide text-lg font-semibold bg-amber-500 text-gray-100 py-2 rounded-lg hover:bg-amber-600 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
