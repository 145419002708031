import React from "react";
import venueImg from "../Assests/event-img/banquet-hall.jpeg";
import carRentalImg from "../Assests/event-img/car-rental.jpg";
import decorationImg from "../Assests/event-img/decoration.JPG";
import makeupImg from "../Assests/event-img/makeup.jpg";
import flowersImg from "../Assests/event-img/flowers.jpg";
import customGiftImg from "../Assests/event-img/gifts.jpeg";
import photographyImg from "../Assests/event-img/photography.jpg";
import djImg from "../Assests/event-img/dj.jpg";
import caterImg from "../Assests/event-img/catering.jpeg";

const servicesData = [
  {
    title: "Decoration",
    description:
      "We provide stunning decoration services to make your event aesthetically pleasing and memorable.",
    imgUrl: decorationImg,
  },
  {
    title: "Catering",
    description:
      "Our catering services offer a wide range of delicious cuisines to delight your guests.",
    imgUrl: caterImg,
  },
  {
    title: "Venue Booking",
    description:
      "We help you find and book the perfect venue for your event, ensuring it meets all your requirements.",
    imgUrl: venueImg,
  },
  {
    title: "Photography",
    description:
      "Capture your precious moments with our professional photography services.",
    imgUrl: photographyImg,
  },
  {
    title: "Makeup Artists",
    description:
      "Our talented makeup artists ensure you look your best for any event.",
    imgUrl: makeupImg,
  },
  {
    title: "DJ and Lights",
    description:
      "Create the perfect atmosphere with our DJ and lighting services.",
    imgUrl: djImg,
  },
  {
    title: "Flower and Garlands",
    description:
      "Beautiful flower arrangements and garlands for all types of events.",
    imgUrl: flowersImg,
  },
  {
    title: "Car Rental",
    description:
      "Reliable car rental services for easy transportation during your event.",
    imgUrl: carRentalImg,
  },
  {
    title: "Customised Gifts",
    description: "We make customized gifts for your loved ones.",
    imgUrl: customGiftImg,
  },
];

const ServiceSection = ({ title, description, imgUrl, reverse }) => (
  <div className={`relative ${reverse ? "lg:col-start-2" : ""}`}>
    <div className=" mx-auto grid max-w-[1240px] grid-flow-col-dense lg:grid-cols-2 gap-8 gap-y-4 px-8">
      <div className={` mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none py-14 lg:px-0 ${reverse ? "lg:col-start-2" : ""}`}>
        <div className="mt-2 md:mt-6">
          <h2 className="text-3xl font-bold tracking-tight text-amber-400">
            {title}
          </h2>
          <p className="mt-2 md:mt-4 text-md md:text-lg text-gray-300">
            {description}
          </p>
          <div className="mt-3 md:mt-6">
            <a
              className="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 text-base font-semibold leading-7 text-black shadow-lg shadow-amber-700  hover:bg-amber-600"
              href="https://api.whatsapp.com/send/?phone=8682960528&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer"
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
      <div
        className={`mt-12 sm:mt-16 lg:mt-0 ${reverse ? "lg:col-start-1" : ""}`}
      >
        <div
          className={`${
            reverse ? "-ml-48 pr-6 md:-ml-16" : "-mr-48 pl-6 md:-mr-16"
          } lg:relative lg:m-0 lg:h-full lg:px-0`}
        >
          <img
            className="w-[200px] rounded-lg border-amber-600 lg:absolute lg:h-full lg:w-auto "
            style={{ color: "transparent" }}
            src={imgUrl}
            alt={title}
          />
        </div>
      </div>
    </div>
  </div>
);

const ServiceSectionSm = ({ title, description, imgUrl }) => {
  return (
    <div className="w-[400px] flex flex-col mt-6 mx-auto">
      <div class="shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
        <img
          class="w-full h-auto rounded-t-xl"
          src={imgUrl}
          alt={title}
        />
        <div class="p-4 md:p-5">
          <h3 class="text-xl font-bold text-amber-500 dark:text-white">
            {title}
          </h3>
          <p class="mt-1 text-gray-300 text-md dark:text-neutral-400">
            {description}
          </p>
          <a
            className="inline-flex rounded-lg bg-amber-400 px-4 py-1.5 mt-4 text-base font-semibold leading-7 text-black shadow-md shadow-amber-700  hover:bg-amber-600"
             href="https://api.whatsapp.com/send/?phone=8682960528&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
};
const Services = () => {
  return (
    <div className="relative overflow-hidden mt-32 pb-32 space-y-12">
      <div className="max-w-[1240px] mx-auto px-6">
        <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-amber-400">
          Our Services
        </h1>
        <p className="mt-4 text-md md:text-lg text-gray-300">
          EventBee offers a wide range of services to make your event
          unforgettable.
        </p>
      </div>
      <div className="hidden lg:block">
        {servicesData.map((service, index) => (
          <ServiceSection
            key={index}
            title={service.title}
            description={service.description}
            imgUrl={service.imgUrl}
            icon={service.icon}
            reverse={index % 2 === 1}
          />
        ))}
      </div>
      {/* Small screen view */}
      <div className="lg:hidden">
        {servicesData.map((service, index) => (
          <ServiceSectionSm
            key={index}
            title={service.title}
            description={service.description}
            imgUrl={service.imgUrl}
            icon={service.icon}
            reverse={index % 2 === 1}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
