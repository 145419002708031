import React from 'react';



const Home = () => {
  return (
    <div class="home-bg flex items-center justify-center">
    <div class='p-4 md:p-8 flex flex-col  justify-center text-white text-center'>
      <h1 class='text-6xl md:text-8xl home-font-h1 uppercase text-shadow-lg shadow-gray-950 '>Event BEE</h1>
      <p class= 'text-3xl md:text-4xl home-font-h1 text-shadow-lg shadow-gray-950 '>Celebrate Your Moments</p>
    </div>
  </div>
  );
};

export default Home;
