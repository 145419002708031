import React from "react";
import mainLogo from "../Assests/EVENT_BEE-LOGO-light.png";
import { FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FiPhoneCall, FiMail } from "react-icons/fi";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-[#eec33d] to-amber-500">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 flex flex-col md:flex-row">
            <a href="/home" className="flex items-center">
              <img src={mainLogo} className="h-26 w-40" alt="Logo" />
            </a>
            <div className="mt-4 md:mt-0 md:ml-8">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">
                Contact
              </h2>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-2 text-white">
                  <FiPhoneCall className="w-4 h-4 text-black" aria-hidden="true" />
                  <div className="whitespace-nowrap">
                    +91-9025128482 <span className="text-black">&</span> +91-8682960528
                  </div>
                </div>
                <div className="flex items-center space-x-2 text-white">
                  <FiMail className="w-4 h-4 text-black" aria-hidden="true" />
                  <div className="underline">contact@eventbee.in</div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">
                Resources
              </h2>
              <ul className="text-white font-medium">
                <li className="mb-4">
                  <a href="#events" className="hover:underline">
                    Events
                  </a>
                </li>
                <li>
                  <a href="#events" className="hover:underline">
                    Gallery
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">
                Legal
              </h2>
              <ul className="text-white font-medium">
                <li className="mb-4">
                  <a href="/" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/" className="hover:underline">
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between ">
          <span className="text-sm text-gray-600 sm:text-center">
            Copyright © 2023{" "}
            <a href="/" className="hover:underline">
              EventBee™
            </a>
            . Designed by{" "}
            <a href="https://namuvi.com/" className="underline whitespace-nowrap">
              NAMUVI Technologies
            </a>
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0 space-x-4">
            <a href="/" className="text-gray-900 hover:text-gray-900">
              <FaFacebookF className="w-4 h-4" aria-hidden="true" />
              <span className="sr-only">Facebook page</span>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=8682960528&text&type=phone_number&app_absent=0" className="text-gray-900 hover:text-gray-900">
              <FaWhatsapp className="w-4 h-4" aria-hidden="true" />
              <span className="sr-only">WhatsApp</span>
            </a>
            <a href="https://www.instagram.com/YOUR_INSTAGRAM_PROFILE" className="text-gray-900 hover:text-gray-900">
              <FaInstagram className="w-4 h-4" aria-hidden="true" />
              <span className="sr-only">Instagram</span>
            </a>
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
