import React from 'react';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Services from './Components/Services';
import OurEvents from './Components/OurEvents';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import WhatsappBtn from './Components/WhatsappBtn';


const App = () => {
    const phoneNumber = '8682960528';
    const message = 'Hello, I have a query about your services.';
  return (
    <div>
      <Navbar />
     
      <section id="home"> 
        <Home/>
      </section>
      <WhatsappBtn phoneNumber={phoneNumber} message={message}/>
      <section id="services" >
        <Services />
      </section>
      <section id="events" >
        <OurEvents />
      </section>
      <section id="about" >
        <About />
      </section>
      <section id="contact" >
        <Contact />
      </section>
      <section id="footer" >
        <Footer />
      </section>
      
    </div>
  );
};

export default App;
